<template>
  <v-dialog
    v-model="showDialog"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-card class="grey lighten-3 elevation-0" tile>
      <v-toolbar dark color="primary" flat tile>
        <v-btn icon dark @click="showDialog = false">
          <v-icon>ph-x</v-icon>
        </v-btn>
        <v-toolbar-title v-text="'Gerenciar Conecta Partner'" />
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn dark text @click="showInfoDialog = true">
            <v-icon v-text="'ph-question'" class="mr-2" /> Ajuda
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-card
        color="white"
        class="mx-auto elevation-0 mt-8 pb-1 grey--text text--darken-1"
        tile
        width="800"
      >
        <v-app-bar
          outlined
          dense
          tile
          class="ma-0 elevation-0 text-h6 font-weight-regular grey--text text--darken-2"
        >
          Configurar informações de parceria
        </v-app-bar>
        <v-card outlined rounded="3" class="px-0 mx-4 my-8" :loading="loading">
          <v-card-title
            class="grey--text text--darken-1 text-subtitle-1 font-weight-medium py-3"
          >
            Status de parceria
          </v-card-title>
          <v-divider />
          <v-row class="d-flex align-center px-4 my-0">
            <v-col
              lg="5"
              md="3"
              sm="12"
              class="py-0 my-0 text-subtitle-2 font-weight-regular"
            >
              Status
            </v-col>
            <v-col lg="7" md="7" sd="12" class="py-0 my-0">
              <v-switch
                @change="openChangeStatusDialog()"
                v-model="status"
                inset
                :loading="loading"
              >
                <template v-slot:label>
                  {{ status && !loading ? "ATIVADO" : "DESATIVADO" }}
                </template>
              </v-switch>
            </v-col>
          </v-row>
          <v-row
            class="d-flex align-center px-4 mt-2 mb-4"
            v-if="resellerStatus && resellerToken !== ''"
          >
            <v-col
              lg="5"
              md="3"
              sd="12"
              class="py-0 my-0 text-subtitle-2 font-weight-regular"
            >
              Código de parceiro
            </v-col>
            <v-col
              lg="7"
              md="7"
              sd="12"
              class="py-0 my-0 grey--text text--darken-1 font-italic"
            >
              <v-row class="pa-0 ma-0 d-flex align-center">
                <v-col cols="10" v-if="!showResellerToken" class="pa-0 ma-0">
                  <v-skeleton-loader
                    type="text"
                    class="mt-1"
                  ></v-skeleton-loader>
                </v-col>
                <v-col cols="10" class="pa-0 ma-0 text-body-2" v-else>{{
                  resellerToken
                }}</v-col>

                <v-col cols="2" class="pa-0 ma-0">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        @click="showResellerToken = !showResellerToken"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon
                          v-text="
                            showResellerToken
                              ? 'ph-fill ph-eye-slash'
                              : 'ph-fill ph-eye'
                          "
                        />
                      </v-btn>
                    </template>
                    <span>{{
                      showResellerToken
                        ? "Ocultar código deste parceiro"
                        : "Mostrar código deste parceiro"
                    }}</span>
                  </v-tooltip>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card>
        <v-card
          outlined
          rounded="3"
          class="px-0 mx-4 mt-8 pb-2 mb-6"
          :disabled="!isReseller"
          :loading="loading"
        >
          <v-card-title
            class="grey--text text--darken-1 text-subtitle-1 font-weight-medium py-3"
          >
            Informações da parceria
          </v-card-title>
          <v-divider />

          <Alert
            class="mx-4 mt-6 py-4"
            width="100%"
            :prominent="false"
            color="warning"
          >
            Veja como funciona o esquema de parcerias antes de editar,
            <span
              class="text-link font-weight-bold ml-1 primary--text text--lighten-3 text-decoration-underline"
              @click="showInfoDialog = true"
              >saiba mais</span
            >.
          </Alert>

          <v-row class="d-flex align-center px-4 my-0">
            <v-col
              lg="5"
              md="3"
              sd="12"
              class="py-0 my-0 text-subtitle-2 font-weight-regular"
            >
              Tipo de parceria

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-text="'ph-question'"
                    v-bind="attrs"
                    v-on="on"
                    class="ml-1"
                    small
                    color="blue darken-2"
                    @click="showInfoDialog = true"
                  />
                </template>
                <span>Entenda como funcionam os tipos de parcerias</span>
              </v-tooltip>
            </v-col>
            <v-col lg="7" md="7" sd="12" class="py-0 my-0">
              <v-combobox
                @change="updateDiscountAndCommission"
                :disabled="!isReseller"
                small-chips
                v-model="conectaPartner.type"
                :items="conectaPartnerTypes"
                required
                item-text="text"
                item-value="value"
              />
            </v-col>
          </v-row>
          <v-row class="d-flex align-center px-4 py-0 my-0">
            <v-col
              lg="5"
              md="3"
              sd="12"
              class="py-0 my-0 text-subtitle-2 font-weight-regular d-flex align-center"
            >
              Conecta Support

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-text="'ph-question'"
                    v-bind="attrs"
                    v-on="on"
                    class="ml-1"
                    small
                    color="blue darken-2"
                  />
                </template>
                <span
                  >Define se os clientes deste parceiro terão acesso ao chat de
                  suporte direto com a Conecta</span
                >
              </v-tooltip>
            </v-col>
            <v-col lg="7" md="7" sd="12" class="py-0 my-0">
              <v-switch
                v-model="conectaPartner.is_support_chat_available"
                inset
                required
                :label="`${
                  conectaPartner.is_support_chat_available
                    ? 'ATIVO'
                    : 'DESATIVADO'
                }`"
              ></v-switch>
            </v-col>
          </v-row>
          <v-row class="d-flex align-center px-4 my-0">
            <v-col
              lg="5"
              md="3"
              sd="12"
              class="py-0 my-0 text-subtitle-2 font-weight-regular"
            >
              Desconto ao cliente final
            </v-col>
            <v-col lg="7" md="7" sd="12" class="py-0 my-0">
              <v-text-field
                :disabled="!isReseller"
                v-model="conectaPartner.discount"
                @change="setToZero('discount')"
                type="number"
                hint="Mínimo de 0 e máximo de 100"
                min="0"
                max="100"
                suffix="%"
                required
                :rules="[rules.isNumber]"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="d-flex align-center px-4 my-0">
            <v-col
              lg="5"
              md="3"
              sd="12"
              class="py-0 my-0 text-subtitle-2 font-weight-regular"
            >
              Comissão
            </v-col>
            <v-col lg="7" md="7" sd="12" class="py-0 my-0">
              <v-text-field
                :disabled="!isReseller"
                v-model="conectaPartner.commission"
                @change="setToZero('commission')"
                type="number"
                hint="Mínimo de 0 e máximo de 100"
                min="0"
                max="100"
                suffix="%"
                required
                :rules="[rules.isNumber]"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-card-actions
            v-if="hasChanges && resellerStatus"
            class="d-flex justify-end my-2 mr-4"
            transition="dialog-bottom-transition"
          >
            <v-btn color="secondary" text class="px-2" @click="resetInfo">
              Resetar
            </v-btn>

            <v-btn
              color="accent"
              class="elevation-0 px-4 ml-2"
              :disabled="disabledSave"
              @click="editConfirmChanges = true"
            >
              Salvar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-card>
    </v-card>

    <!-- DIALOG DE INFORMAÇÕES -->
    <HeaderDialog
      width="900"
      :show="showInfoDialog"
      id="info_conecta_partner"
      title="Conecta Partners - Ajuda"
      :persistent="false"
      @close="closeHelpDialog()"
      read-only
    >
      <template v-slot:body>
        <p>Informações úteis para preenchimento dos campos</p>

        <v-simple-table max-height="400" fixed-header>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left py-2">Nome do campo</th>
                <th class="text-left py-2">Descrição</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, i) in informationReseller" :key="i">
                <td class="font-weight-medium" v-text="item.title + '*'" />
                <td v-html="item.description" />
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <p class="font-weight-bold caption mx-4 mt-2">* campos obrigatórios</p>

        <Alert
          class="mx-auto py-4"
          width="100%"
          :prominent="false"
          color="info"
        >
          No caso de um parceiro ser desativado e nenhum outro Conecta Partner
          ser escolhido na desativação, os clientes vinculados à ele passarão
          automaticamente para a gerência da Conecta Nuvem.
        </Alert>
      </template>
    </HeaderDialog>

    <!-- DIALOG DE CONFIRMAÇÃO DA MUDANÇA DE STATUS -->
    <SimpleDialog
      id="change_status_reseller"
      title="Confirmar alterações de status"
      :show="dialogEditStatus"
      @close="cancelStatusChanges"
      close-text="Cancelar"
      width="700"
      @action="changeIsResellerStatus"
      :loading="loading"
    >
      <template v-slot:body>
        <p class="mt-2 text-title-1">
          {{
            !status
              ? "Você tem certeza que seja remover a atribuição de parceiro deste cliente?"
              : "Você tem certeza que deseja tornar este cliente em um Conecta Partner?"
          }}
        </p>
        <Alert
          v-if="!status"
          class="mx-auto py-4 mt-4"
          color="error"
          :prominent="false"
        >
          Ao remover este cliente dos Conecta Partners
          <span class="font-weight-bold"
            >todos os clientes dele deverão ser redirecionados para outro
            parceiro</span
          >
        </Alert>
        <Alert
          v-if="!status"
          class="mx-auto py-4 mt-4"
          color="warning"
          :prominent="false"
        >
          Remover a parceria com este cliente implica que os clientes dele
          deverão receber um novo parceiro. Deseja atribuir um novo parceiro
          atual?
        </Alert>

        <v-card
          outlined
          rounded="3"
          class="px-0 mx-0 my-8"
          :loading="loading"
          v-if="!status"
        >
          <v-card-title
            class="grey--text text--darken-1 text-subtitle-1 font-weight-medium py-3"
          >
            Atribuir clientes deste para outro parceiro
          </v-card-title>
          <v-divider />
          <v-row class="d-flex align-center px-4 my-0">
            <v-col
              lg="5"
              md="3"
              sd="12"
              class="py-0 my-0 text-subtitle-2 font-weight-regular"
            >
              Novo parceiro atual
            </v-col>
            <v-col lg="7" md="7" sd="12" class="py-0 my-0">
              <v-combobox
                small-chips
                v-model="responsiblePartner"
                :items="resellers"
                required
                return-object
                hide-no-data
                hide-selected
                item-text="name"
              />
            </v-col>
          </v-row>
        </v-card>
      </template>
    </SimpleDialog>

    <!-- DIALOG DE CONFIRMAÇÃO DAS ALTERAÇÕES DE INFORMAÇÕES DO PACEIRO -->
    <SimpleDialog
      id="change_info_reseller"
      title="Confirmar alteração nas informações do parceiro"
      :show="editConfirmChanges"
      @close="editConfirmChanges = false"
      width="600"
      readOnly
    >
      <template v-slot:body>
        <p class="mt-2 text-title-1">
          Você tem certeza que deseja salvar as alterações deste parceiro?
        </p>

        <Alert class="mx-auto py-4 mt-4" color="info" :prominent="false">
          As alterações serão salvas imediatamente e já serão utilizadas para os
          próximos faturamentos.
        </Alert>

        <v-row class="mt-8 mx-1">
          <v-spacer />
          <v-btn
            color="grey"
            text
            class="elevation-0 px-4 py-2 ml-2 text-none font-weight-medium text-body-2"
            @click="stopEdit"
          >
            {{ startEditInfo ? "Cancelar" : "Fechar" }}
          </v-btn>

          <v-btn
            color="accent"
            class="elevation-0 px-4 py-2 ml-2 text-none font-weight-medium text-body-2"
            dark
            @click="startEdit"
            :loading="loading"
          >
            Confirmar alterações
          </v-btn>
        </v-row>
      </template>
    </SimpleDialog>
  </v-dialog>
</template>
<script>
import HeaderDialog from "@/components/base/HeaderDialog";
import { partnerType } from "@/helpers/variables/backendConstants";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "AddEditReseller",
  props: {
    show: { type: Boolean, required: true },
    clientKey: { type: String, required: true },
    resellerStatus: { type: Boolean, required: true },
    resellerInfo: { type: Object, default: () => null },
    resellerToken: { type: String, default: "" },
  },
  components: { HeaderDialog },
  data() {
    return {
      rules: {
        isNumber: (value) =>
          (parseInt(value) >= 0 && parseInt(value) <= 100) ||
          "Insira um número válido entre 0 e 100",
      },
      showResellerToken: false,
      editConfirmChanges: false,
      responsiblePartner: null,
      dialogEditStatus: false,
      startEditInfo: false,
      loading: false,
      showInfoDialog: false,
      status: false,
      default: {
        type: { value: "DIRECT", text: "Direta" },
        commission: 20,
        discount: 0,
        is_support_chat_available: false,
      },
      conectaPartner: {
        type: { value: "DIRECT", text: "Direta" },
        commission: 20,
        discount: 0,
        is_support_chat_available: false,
      },
      oldInformation: {},
      conectaPartnerTypes: [
        { value: partnerType.DIRECT, text: "Direta" },
        { value: partnerType.INTERMEDIARY, text: "Intermediária" },
      ],
      informationReseller: [
        {
          title: "Status",
          description: `Se refere a condição do cliente como Conecta Partner. Quando <code>ATIVO</code>,
          o cliente é um parceiro e quando <code>DESATIVADO</code> o cliente não é parceiro.`,
        },
        {
          title: "Tipo de parceria",
          description: `Se refere à categoria de parceria que o Conecta Partner se encontra. Por padrão, é definido como <code>DIRETA</code>.
            <br/><br />Na modalidade <code>DIRETA</code>, o parceiro possui as seguintes características:<br/>
              <ul>
                <li>Fatura o cliente final</li>
                <li>Possuem 20% de comissão por padrão</li>
                <li>Cliente final vê o valor do plano</li>
              </ul>
            <br/>Na modalidade <code>INTERMEDIÁRIO</code>, o parceiro possui as seguintes características:<br/>
              <ul>
                <li>Fatura o parceiro</li>
                <li>Clientes deste possuem 20% de desconto por padrão</li>
                <li>O parceiro pode revender os produtos no preço desejar (Suite não controla isso)</li>
                <li>O parceiro fatura os seus clientes</li>
                <li>Não teremos tratativas financeiras com os clientes finais.</li>
              </ul>`,
        },
        {
          title: "Conecta Support",
          description: `Define se os clientes daquele parceiro terão acesso ou não ao atendimento via chat de suporte com a Conecta.`,
        },
        {
          title: "Desconto",
          description: `Indica o valor de desconto que os clientes deste parceiro receberão por padrão em seus planos. Se não definido, o padrão é 20%.`,
        },
        {
          title: "Comissão",
          description: `Referente ao percentual que o parceiro irá receber após o faturamento de um plano vendido por ele.`,
        },
      ],
    };
  },
  mounted() {
    this.status = this.resellerStatus;
    this.loadInfo();
  },
  computed: {
    ...mapGetters(["token", "resellers", "conectaSuiteResellerToken"]),

    disabledSave() {
      return (
        this.conectaPartner.commission === "" ||
        this.conectaPartner.commission === null ||
        this.conectaPartner.discount === "" ||
        this.conectaPartner.discount === null
      );
    },

    isReseller() {
      return this.resellerStatus;
    },

    showDialog: {
      get() {
        return this.show;
      },
      set() {
        this.$emit("close");
      },
    },
    hasChanges() {
      const keys = Object.keys(this.oldInformation);
      return keys.find(
        (key) => this.oldInformation[key] !== this.conectaPartner[key]
      ) ||
        this.conectaPartner.is_support_chat_available !==
          this.oldInformation.is_support_chat_available
        ? true
        : false;
    },
  },
  watch: {
    status() {
      //this.conectaPartner = Object.assign({}, this.default);
      this.oldInformation = Object.assign({}, this.default);
      if (!this.resellerStatus && !this.status) {
        this.conectaPartner.is_support_chat_available = false;
      }
    },
    dialogEditStatus() {
      if (!this.status) {
        this.responsiblePartner = this.resellers.find(
          (reseller) => reseller.cs_token === this.conectaSuiteResellerToken
        );
      } else {
        this.$emit("hideShowScrollBar");
      }
    },
    editConfirmChanges() {
      if (this.editConfirmChanges) {
        this.$emit("hideShowScrollBar");
      }
    },
    showDialog() {
      if (this.showDialog) {
        this.loadInfo();
      }
    },
    "conectaPartner.is_support_chat_available"() {
      this.$emit("hideShowScrollBar");
    },
    "conectaPartner.commission"() {
      this.$emit("hideShowScrollBar");
    },
    "conectaPartner.discount"() {
      this.$emit("hideShowScrollBar");
    },
    "conectaPartner.status"() {
      this.$emit("hideShowScrollBar");
    },
  },
  methods: {
    ...mapActions(["getResellers", "getCompany"]),

    closeHelpDialog() {
      this.showInfoDialog = false;
      this.$emit("hideShowScrollBar");
    },

    updateDiscountAndCommission(event) {
      const newValue = Object.assign({}, event);
      if (newValue.value === "DIRECT") {
        this.conectaPartner.commission = "20";
        this.conectaPartner.discount = "0";
      } else if (newValue.value === "INTERMEDIARY") {
        this.conectaPartner.commission = "0";
        this.conectaPartner.discount = "20";
      }
    },

    setToZero(field) {
      if (field === "commission") {
        if (this.conectaPartner.commission === "") {
          this.conectaPartner.commission = "0";
        }
      }
      if (field === "discount") {
        if (this.conectaPartner.discount === "") {
          this.conectaPartner.discount = "0";
        }
      }
    },

    cancelStatusChanges() {
      this.dialogEditStatus = false;
      this.status = !this.status;
    },

    openChangeStatusDialog() {
      if (this.status !== this.resellerStatus) {
        this.dialogEditStatus = true;
      }
    },

    resetInfo() {
      this.conectaPartner = Object.assign({}, this.oldInformation);
    },

    loadInfo() {
      this.status = this.resellerStatus;
      if (Object.keys(this.resellerInfo).length > 0) {
        this.conectaPartner = Object.assign({}, this.resellerInfo);
        this.conectaPartner.type = Object.assign(
          {},
          this.conectaPartnerTypes.find(
            (type) => this.resellerInfo.type === type.value
          )
        );
        this.conectaPartner.discount = this.conectaPartner.discount * 100;
        this.conectaPartner.commission = this.conectaPartner.commission * 100;
      }
      this.oldInformation = Object.assign({}, this.conectaPartner);
    },

    async changeIsResellerStatus() {
      this.loading = true;

      let url =
        process.env.VUE_APP_API_BASE_URL +
        "/resellers/" +
        this.clientKey +
        "/is_reseller";

      let payload = {
        is_reseller: this.status,
      };

      const config = {
        headers: { Authorization: this.token },
      };

      if (!this.status) {
        payload.new_reseller_token = this.responsiblePartner.cs_token;
      }

      await this.$axios
        .put(url, payload, config)
        .then(() => {
          this.$emit("startLoadClient");
          this.$emit("showSuccessUpdateResellerStatus");
          this.dialogEditStatus = false;
          if (!this.status) {
            this.showDialog = false;
          }
        })
        .catch((err) => {
          this.$emit("showFailureUpdateResellerStatus");
          this.showDialog = false;
          this.dialogEditStatus = false;
          console.error("changeIsResellerStatus()", err);
        });

      this.loading = false;
    },
    async startEdit() {
      this.startEditInfo = true;
      if (Object.values(this.conectaPartner)) {
        await this.editPartnerInfo(this.conectaPartner);
      }
      this.startEditInfo = false;
    },
    async stopEdit() {
      if (this.startEditInfo) {
        await this.editPartnerInfo(this.oldInformation);
        this.conectaPartner = Object.assign({}, this.oldInformation);
      } else {
        this.conectaPartner = Object.assign({}, this.default);
        this.editConfirmChanges = false;
      }
    },
    async editPartnerInfo(info) {
      this.loading = true;

      let url =
        process.env.VUE_APP_API_BASE_URL +
        "/resellers/" +
        this.clientKey +
        "/config";

      let payload = {
        type: info.type.value,
        commission: parseFloat(info.commission) / 100,
        discount: parseFloat(info.discount) / 100,
        is_support_chat_available: info.is_support_chat_available,
      };

      const config = {
        headers: { Authorization: this.token },
      };

      await this.$axios
        .put(url, payload, config)
        .then(() => {
          this.showDialog = false;
          this.$emit("startLoadClient");
          this.getCompany();
          this.$emit("showSuccessUpdateResellerStatus");
          this.editConfirmChanges = false;
        })
        .catch((err) => {
          this.$emit("showFailureUpdateResellerStatus");
          this.showDialog = false;
          console.error("changeIsResellerStatus()", err);
          this.editConfirmChanges = false;
        });

      this.loading = false;
    },
  },
};
</script>
