<template>
  <v-card
    :class="`mx-5 my-5 elevation-0 ${showDataTable ? 'pb-4' : 'pb-0'}`"
    outlined
  >
    <v-card-title
      :style="
        showDataTable ? 'border-bottom: 1px solid rgba(0, 0, 0, 0.12)' : ''
      "
    >
      <span class="my-auto">Histórico de alterações</span>
      <download-csv
        :data="prepareDataForExport"
        :labels="labels"
        :fields="planUpdateLogFields"
        v-if="showDataTable && $vuetify.breakpoint.lgAndUp"
      >
        <v-btn
          text
          class="text-none text-body-2 font-weight-bold ml-4"
          color="accent"
          :disabled="tableLoad"
        >
          <svg
            style="width: 24px; height: 24px"
            viewBox="0 0 24 24"
            class="mr-2"
          >
            <path
              fill="currentColor"
              d="M2 12H4V17H20V12H22V17C22 18.11 21.11 19 20 19H4C2.9 19 2 18.11 2 17V12M12 15L17.55 9.54L16.13 8.13L13 11.25V2H11V11.25L7.88 8.13L6.46 9.55L12 15Z"
            />
          </svg>
          Baixar CSV
        </v-btn>
      </download-csv>
      <!--FILTER MENU-->
      <v-spacer v-if="$vuetify.breakpoint.smAndUp" />

      <v-tooltip v-if="showDataTable && $vuetify.breakpoint.lgAndUp" left>
        <template v-slot:activator="{ on: tooltip }">
          <v-btn
            icon
            class="mr-2"
            color="secondary"
            v-on="{ ...tooltip }"
            @click="getCompanyPlanLog()"
            :loading="tableLoad"
          >
            <v-icon
              v-text="'ph-arrow-clockwise'"
              v-on="{ ...tooltip }"
              @click="getCompanyPlanLog()"
              :loading="tableLoad"
            />
          </v-btn>
        </template>
        <span>Atualizar</span>
      </v-tooltip>

      <v-autocomplete
        v-if="showDataTable && $vuetify.breakpoint.lgAndUp"
        class="mb-1 mr-4"
        dense
        :items="periods"
        v-model="period"
        item-text="name"
        label="Período"
        outlined
        style="max-width: 220px; height: 35px"
        :disabled="tableLoad"
      >
        <template v-slot:append>
          <v-icon v-text="'ph-fill ph-caret-down'" />
          <v-icon v-text="'ph - calendar - blank'" />
        </template>
      </v-autocomplete>

      <v-btn @click="changeShowTable()" :class="`ml-auto my-auto`" icon large>
        <v-icon
          v-text="showDataTable ? 'ph-caret-up' : 'ph-caret-down'"
          size="28"
        />
      </v-btn>
    </v-card-title>

    <!-- AVISO DE FALHA EM LER O LOG -->
    <Alert
      v-if="showSnackBarFailure && showDataTable"
      class="mx-5 mt-4 py-4"
      :prominent="false"
      color="error"
    >
      Não foi possível carregar os logs de alteração de planos deste cliente,
      por favor, tente novamente mais tarde.
    </Alert>

    <v-card-text
      v-if="$vuetify.breakpoint.mdAndDown && showDataTable"
      class="mt-5"
    >
      <v-btn
        outlined
        block
        color="secondary lighten-2"
        class="text-none text-body-2 font-weight-bold mb-5"
        @click="getCompanyPlanLog()"
        :loading="tableLoad"
      >
        <v-icon v-text="'ph-arrow-clockwise'" left />
        Atualizar histórico
      </v-btn>

      <download-csv
        :data="prepareDataForExport"
        :labels="labels"
        :fields="planUpdateLogFields"
      >
        <v-btn
          block
          outlined
          class="text-none text-body-2 font-weight-bold mb-5"
          color="accent"
          :disabled="tableLoad"
        >
          <svg
            style="width: 24px; height: 24px"
            viewBox="0 0 24 24"
            class="mr-2"
          >
            <path
              fill="currentColor"
              d="M2 12H4V17H20V12H22V17C22 18.11 21.11 19 20 19H4C2.9 19 2 18.11 2 17V12M12 15L17.55 9.54L16.13 8.13L13 11.25V2H11V11.25L7.88 8.13L6.46 9.55L12 15Z"
            />
          </svg>
          Baixar histórico
        </v-btn>
      </download-csv>
      <v-autocomplete
        class="mb-1"
        dense
        :items="periods"
        v-model="period"
        item-text="name"
        label="Período"
        outlined
        style="height: 35px"
        hide-details
        :disabled="tableLoad"
      >
        <template v-slot:append>
          <v-icon v-text="'ph-caret-down'" />
          <v-icon v-text="'ph-calendar-blank'" />
        </template>
      </v-autocomplete>
    </v-card-text>

    <v-card-text v-if="showDataTable" class="mx-0 px-0">
      <v-data-table
        :headers="tableHeader"
        :items="companyPlanLog"
        :items-per-page="10"
        :loading="tableLoad"
        :expanded.sync="expanded"
        :single-expand="false"
        item-key="log_changed_date"
        show-expand
        class="table-header"
        loading-text="Atualizando dados... Por favor, aguarde"
      >
        <!-- ATOR TOOLTIP-->
        <template v-slot:[`header.user_changer_email`]="{ header }">
          {{ header.text }}
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                color="grey"
                dark
                v-bind="attrs"
                v-on="on"
                v-text="'ph-info'"
                small
              />
            </template>
            <span class="font-weight-medium"> Aquele que executou a ação </span>
          </v-tooltip>
        </template>

        <!-- DATA DE REGISTRO  -->
        <template v-slot:[`item.log_changed_date`]="{ item }">
          {{ formatDateAndHour(item.log_changed_date) }}
        </template>

        <!-- EVENTO -->
        <template v-slot:[`item.context`]="{ item }">
          <v-chip small>
            {{ logContext(item) }}
          </v-chip>
        </template>

        <!-- ATOR -->
        <template v-slot:[`item.user_changer_email`]="{ item }">
          <span>
            {{
              item.author
                ? item.author.email || "-"
                : item.user_changer_email || "-"
            }}
          </span>
        </template>

        <!-- Objeto modificado -->
        <template v-slot:[`item.changed_object`]="{ item }">
          <span v-if="item.context == LOG_CONTEXT.USER_DELEGATES">
            {{ item.object ? item.object.email : "-" }}
          </span>
          <span v-else-if="item.context == LOG_CONTEXT.USER_PERMISSION">
            {{ item.user_modified_email || "-" }}
          </span>
          <span v-else-if="item.context == LOG_CONTEXT.COMPANY_PLAN">
            {{ item.plan_name || "-" }}
          </span>
          <span v-if="item.context == LOG_CONTEXT.EDIT_TAGS">
            Tags de assinatura
          </span>
          <span v-if="item.context == LOG_CONTEXT.EDIT_USER_TAGS">
            Tags de assinatura de usuário
          </span>
        </template>
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length" class="pl-4 pt-4 pb-4 grey lighten-4">
            <div v-if="item.context == LOG_CONTEXT.USER_PERMISSION">
              <li class="py-1">
                {{
                  item.permission == "admin"
                    ? "Permissão de Gerente concedida"
                    : "Permissão de Gerente removida"
                }}
              </li>
              <li class="py-1">
                Módulo:
                {{ item.product ? historyLogModuleName[item.product] : "-" }}
              </li>
            </div>
            <div v-else-if="item.context == LOG_CONTEXT.USER_DELEGATES">
              <li class="py-1">
                E-mail do delegado:
                {{ item.object ? `${item.object.delegate_email}` : "-" }}
              </li>
              <li class="py-1">
                Ação:
                {{ item.object ? `${LOG_ACTION[item.object.action]}` : "-" }}
              </li>
              <li class="py-1">
                Descrição:
                <span
                  v-html="
                    item.description
                      ? translateDescription(item).toLowerCase()
                      : '-'
                  "
                />
              </li>
            </div>
            <div v-else-if="item.context == LOG_CONTEXT.COMPANY_PLAN">
              <li class="py-1">
                Preço:
                {{ item.price ? formatMoney(item.price) : "-" }}
              </li>
              <li class="py-1">
                Desconto:
                {{ item.discount ? formatNumberToPercent(item.discount) : "-" }}
              </li>
              <li class="py-1">
                Licenças:
                {{ item.company_users_number || "-" }}
              </li>
              <li class="py-1">
                Limite de licenças:
                {{ item.max_licenses || "-" }}
              </li>
              <li class="py-1">
                Plano:
                {{ planPeriodic[item.subscription_type] }}
              </li>
              <li class="py-1">
                Status:
                {{ status[item.status] }}
              </li>
              <li class="py-1">
                Data de início:
                {{ validateDatetime(item.init_date) }}
              </li>
              <li class="py-1">
                Data de fim:
                {{ validateDatetime(item.end_date) }}
              </li>
              <li class="py-1">
                Data de início do Trial:
                {{ validateDatetime(item.init_trial) }}
              </li>
              <li class="py-1">
                Data de fim do Trial:
                {{ validateDatetime(item.end_trial) }}
              </li>
              <li class="py-1">
                Data final da transação:
                {{ validateDatetime(item.end_transaction) }}
              </li>
              <li class="py-1">
                Data da próxima cobrança:
                {{ validateDatetime(item.next_billing) }}
              </li>
              <li class="py-1">
                Método de pagamento:
                {{
                  item.payment_method
                    ? $t(
                        `selfCheckout.${
                          item.payment_method == "BANK_SLIP"
                            ? "bankSlip"
                            : "creditCard"
                        }`
                      )
                    : $t("common.notInformed")
                }}
              </li>
            </div>
            <div
              v-if="
                item.context == LOG_CONTEXT.EDIT_TAGS ||
                item.context == LOG_CONTEXT.EDIT_USER_TAGS
              "
            >
              <span
                v-if="isEditUserTagsDescription(item.description)"
                v-html="translateDescription(item)"
              ></span>
              <li
                class="py-1"
                v-for="(key, i) in getObjectKeys(item.object.changes)"
                :key="i"
              >
                {{
                  editableCompanyTags[key]?.keyName ||
                  ddiTags[key]?.keyName ||
                  userEditableTags[key]?.keyName
                }}:
                <span class="font-weight-medium"
                  >" {{ item.object.changes[key].old_value }} "
                </span>
                alterado para
                <span class="font-weight-medium"
                  >" {{ item.object.changes[key].new_value }} "</span
                >
              </li>
            </div>
          </td>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import JsonCSV from "@/components/main/shared/download.vue";
import {
  getChangedTagValues,
  translateDescription,
} from "@/helpers/services/logs";
import {
  formatDateAndHour,
  formatMoney,
  formatNumberToPercent,
  getObjectKeys,
  subtractDays,
  subtractMonths,
} from "@/helpers/services/utils";
import { LOG_ACTION, LOG_CONTEXT } from "@/helpers/variables/logs";
import {
  ddiTags,
  editableCompanyTags,
  userEditableTags,
} from "@/helpers/variables/tags";
import {
  historyLogContext,
  historyLogModuleName,
  planPeriodic,
  status,
} from "@/helpers/variables/translateString";
import { mapGetters } from "vuex";

export default {
  name: "CardHistoryLog",
  props: {
    clientKey: { type: String, required: true },
  },
  components: { "download-csv": JsonCSV },

  data() {
    return {
      editableCompanyTags,
      ddiTags,
      userEditableTags,
      LOG_ACTION,
      LOG_CONTEXT,
      planPeriodic,
      status,
      historyLogContext,
      historyLogModuleName,
      requestStarted: false,
      showDataTable: false,
      companyPlanLog: [],
      showSnackBarFailure: false,
      tableLoad: false,
      expanded: [],
      tableHeader: [
        {
          text: "Data do registro",
          value: "log_changed_date",
          width: 180,
          sortable: false,
        },
        {
          text: "Evento",
          value: "context",
        },
        {
          text: "Ator",
          value: "user_changer_email",
        },
        {
          text: "Objeto modificado",
          value: "changed_object",
        },
        {
          text: "",
          value: "data-table-expand",
          sortable: false,
        },
      ],
      period: "Últimos 7 dias",
      customPeriod: [],
      periods: [
        { name: "Últimos 6 meses", id: 0 },
        { name: "Últimos 7 dias", id: 1 },
      ],
      planUpdateLogFields: [
        "log_changed_date",
        "context",
        "user_changer_email",
        "modified_object",
      ],
      labels: {
        log_changed_date: "Data do registro",
        context: "Evento",
        user_changer_email: "Ator",
        modified_object: "Objeto modificado",
      },
    };
  },

  computed: {
    ...mapGetters(["token"]),

    prepareDataForExport() {
      return this.companyPlanLog.map((log) => {
        let actor = log.user_changer_email || "-";
        let modified_object = log.author ? log.author.email : "-";

        switch (log.context) {
          case LOG_CONTEXT.USER_DELEGATES:
            actor = log.author ? log.author.email : "-";
            break;
          case LOG_CONTEXT.USER_PERMISSION:
            modified_object = log.user_modified_email || "-";
            break;
          case LOG_CONTEXT.COMPANY_PLAN:
            modified_object = log.plan_name || "-";
            break;
          case LOG_CONTEXT.EDIT_TAGS:
            actor = log.author.email;
            modified_object = getChangedTagValues(log);
            break;
        }

        return {
          log_changed_date: this.formatDateAndHour(log.log_changed_date),
          context: historyLogContext[log.context],
          user_changer_email: actor,
          modified_object: modified_object,
          price: formatMoney(log.price),
          discount: formatNumberToPercent(log.discount),
          company_users_number: log.company_users_number,
          max_licenses: log.max_licenses || "-",
          subscription_type: planPeriodic[log.subscription_type],
          status: status[log.status],
          init_date: this.validateDatetime(log.init_date),
          end_date: this.validateDatetime(log.end_date),
          init_transaction: this.validateDatetime(log.init_transaction),
          next_billing: this.validateDatetime(log.next_billing),
        };
      });
    },
  },

  watch: {
    period() {
      this.getCompanyPlanLog();
    },
  },

  methods: {
    getObjectKeys,

    formatMoney,

    formatDateAndHour,

    formatNumberToPercent,

    translateDescription,

    getChangedTagValues,

    changeShowTable() {
      this.showDataTable = !this.showDataTable;
      if (!this.requestStarted) {
        this.getCompanyPlanLog();
      }
    },

    isEditUserTagsDescription(description) {
      if (description.includes("EDITED_USER_TAGS")) {
        return true;
      }
      return false;
    },

    getCompanyPlanLog() {
      this.requestStarted = true;
      this.tableLoad = true;

      const date =
        this.period === "Últimos 7 dias" ? subtractDays(7) : subtractMonths(6);

      const url = `${process.env.VUE_APP_API_BASE_URL}/clients/${this.clientKey}/history?from_date=${date}`;
      this.$axios
        .get(url, {
          headers: { Authorization: this.token },
        })
        .then((response) => {
          this.companyPlanLog = response["data"]["response"];
        })
        .catch(() => {
          this.showSnackBarFailure = true;
        })
        .finally(() => {
          this.tableLoad = false;
        });
    },

    validateDatetime(datetime) {
      return datetime ? formatDateAndHour(datetime, true) : "-";
    },

    logContext(log) {
      if (log.context === "user_permission") {
        if (log.permission === "admin") return "Concedeu permissão";
        return "Removeu permissão";
      }
      return historyLogContext[log.context];
    },
  },
};
</script>

<style scoped>
.table-header {
  white-space: nowrap;
}
</style>
