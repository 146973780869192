<template>
  <v-progress-circular
    size="20"
    width="3"
    color="primary"
    indeterminate
    v-if="loading"
  />
  <v-menu v-else :disabled="loading" offset-y :close-on-content-click="false">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="grey lighten-1"
        :class="`${
          small
            ? 'text-subtitle-2 text-none py-4 ml-0 mr-1 pl-2 pr-1'
            : 'client-list-button'
        } breadcrumb-link font-weight-regular`"
        outlined
        dark
        large
        v-bind="attrs"
        v-on="on"
        :small="small"
      >
        {{ clientDisplayName }}
        <v-icon class="ml-1" size="18" v-text="'ph-fill ph-caret-down'" />
      </v-btn>
    </template>

    <v-card>
      <v-card-text>
        <v-text-field
          v-model="searchString"
          autofocus
          placeholder="Nome/Domínio do cliente"
          outlined
          color="accent"
          prepend-inner-icon="ph-magnifying-glass"
          class="mb-0 pb-0"
          hide-details
          :loading="loadingCustomers"
        />
      </v-card-text>

      <v-card-text
        v-if="loadingCustomers"
        class="text-body-2 font-italic text-center pb-4"
      >
        Carregando...
      </v-card-text>

      <v-card-text v-if="!searchClient && currentPage">
        <v-btn
          text
          block
          color="blue darken-2"
          class="text-none text-body-2 font-weight-medium"
          @click="backToClient"
        >
          <v-icon left v-text="'ph-arrow-left'" />
          Voltar para {{ clientName }}
        </v-btn>
      </v-card-text>

      <v-card-text
        v-if="
          searchList.length === 0 && searchString !== '' && !loadingCustomers
        "
        class="text-center font-italic grey--text text--darken-1"
      >
        Nenhum resultado encontrado
      </v-card-text>

      <v-list
        v-if="hasResults"
        :width="!small ? 300 : ''"
        class="overflow-y-auto py-0"
        max-height="300"
      >
        <v-list-item>
          <v-list-item-content>
            <v-list-item
              class="search-item"
              two-line
              exact
              v-for="{ name, main_domain, key } in searchList"
              :key="key"
              :disabled="currentClientKey === key"
              @click="goToClient(key, main_domain)"
            >
              <v-list-item-content>
                <v-list-item-title class="search-title">{{
                  name
                }}</v-list-item-title>
                <v-list-item-subtitle class="caption">{{
                  main_domain
                }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card>
  </v-menu>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  props: {
    clientName: { type: String, required: true },
    currentPage: { type: String, default: "" },
    small: { type: Boolean, default: false },
  },
  data() {
    return {
      searchClient: "",
      searchList: [],
      loading: false,
      loadingCustomers: false,
      timeout: null,
    };
  },
  computed: {
    ...mapGetters(["clients", "clientsSize"]),

    searchString: {
      get() {
        return this.searchClient;
      },
      set(val) {
        if (this.timeout) clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          if (val && val.constructor === String) {
            this.searchClient = val.trim();
          } else {
            this.searchClient = val;
          }
        }, 600);
      },
    },

    currentClientKey() {
      return this.$route.query.key;
    },

    hasResults() {
      return this.searchList.length > 0 && !this.loadingCustomers;
    },

    clientDisplayName() {
      return this.small
        ? this.clientName.substr(0, 13) + "..."
        : this.clientName.length > 24
        ? this.clientName.substr(0, 24) + "..."
        : this.clientName;
    },
  },

  watch: {
    async searchClient() {
      this.searchList = await this.searchByClients();
    },
  },

  methods: {
    ...mapActions(["getClients", "fetchClientsPage"]),

    ...mapMutations(["setClients", "setClientsSize"]),

    goToClient(key, main_domain) {
      this.currentClientKey === key
        ? null
        : this.$router.replace({
            path: "/reseller/client",
            query: { domain: main_domain, key: key },
          });
      this.$emit("reload");
    },

    backToClient() {
      this.$router.replace({
        path: "/reseller/client",
        query: { key: this.$route.query.key },
      });
    },

    async searchByClients() {
      this.loadingCustomers = true;

      const query = {
        name: this.searchClient,
        main_domain: this.searchClient,
      };

      return await this.fetchClientsPage(query)
        .then(({ data }) => {
          this.loadingCustomers = false;

          return data.clients;
        })
        .catch((error) => {
          this.loadingCustomers = false;
          console.error("searchByClients() ", error);
          return [];
        });
    },
  },

  beforeMount() {
    if (!this.clients.length) {
      this.searchList = this.clients;
    }
  },
};
</script>
<style scoped>
.client-list-button {
  border: 2px solid #ddd !important;
  padding: 18px 10px !important;
  margin: 0px !important;
  text-transform: none !important;
  letter-spacing: 0px !important;
  font-size: 16px !important;
}

.small {
  border: 2px solid #ddd !important;
}

.search-box {
  margin: 0px !important;
}
.search-item {
  margin: 0px !important;
  background: #fff !important;
}
.search-title {
  font-size: 14px !important;
}
</style>
