var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.loading)?_c('v-progress-circular',{attrs:{"size":"20","width":"3","color":"primary","indeterminate":""}}):_c('v-menu',{attrs:{"disabled":_vm.loading,"offset-y":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({class:`${
        _vm.small
          ? 'text-subtitle-2 text-none py-4 ml-0 mr-1 pl-2 pr-1'
          : 'client-list-button'
      } breadcrumb-link font-weight-regular`,attrs:{"color":"grey lighten-1","outlined":"","dark":"","large":"","small":_vm.small}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.clientDisplayName)+" "),_c('v-icon',{staticClass:"ml-1",attrs:{"size":"18"},domProps:{"textContent":_vm._s('ph-fill ph-caret-down')}})],1)]}}])},[_c('v-card',[_c('v-card-text',[_c('v-text-field',{staticClass:"mb-0 pb-0",attrs:{"autofocus":"","placeholder":"Nome/Domínio do cliente","outlined":"","color":"accent","prepend-inner-icon":"ph-magnifying-glass","hide-details":"","loading":_vm.loadingCustomers},model:{value:(_vm.searchString),callback:function ($$v) {_vm.searchString=$$v},expression:"searchString"}})],1),(_vm.loadingCustomers)?_c('v-card-text',{staticClass:"text-body-2 font-italic text-center pb-4"},[_vm._v(" Carregando... ")]):_vm._e(),(!_vm.searchClient && _vm.currentPage)?_c('v-card-text',[_c('v-btn',{staticClass:"text-none text-body-2 font-weight-medium",attrs:{"text":"","block":"","color":"blue darken-2"},on:{"click":_vm.backToClient}},[_c('v-icon',{attrs:{"left":""},domProps:{"textContent":_vm._s('ph-arrow-left')}}),_vm._v(" Voltar para "+_vm._s(_vm.clientName)+" ")],1)],1):_vm._e(),(
        _vm.searchList.length === 0 && _vm.searchString !== '' && !_vm.loadingCustomers
      )?_c('v-card-text',{staticClass:"text-center font-italic grey--text text--darken-1"},[_vm._v(" Nenhum resultado encontrado ")]):_vm._e(),(_vm.hasResults)?_c('v-list',{staticClass:"overflow-y-auto py-0",attrs:{"width":!_vm.small ? 300 : '',"max-height":"300"}},[_c('v-list-item',[_c('v-list-item-content',_vm._l((_vm.searchList),function({ name, main_domain, key }){return _c('v-list-item',{key:key,staticClass:"search-item",attrs:{"two-line":"","exact":"","disabled":_vm.currentClientKey === key},on:{"click":function($event){return _vm.goToClient(key, main_domain)}}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"search-title"},[_vm._v(_vm._s(name))]),_c('v-list-item-subtitle',{staticClass:"caption"},[_vm._v(_vm._s(main_domain))])],1)],1)}),1)],1)],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }